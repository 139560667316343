/**
 * @author johan.ahman@svenskaspel.se (Johan Åhman)
 */
'use strict';

import PropTypes from 'prop-types';
import './../stylesheets/dots.less';

const Dots = (props) => {
  const classNames = ['dots'];

  if (props.branding) {
    classNames.push(`dots-${props.branding}`);
  } else {
    classNames.push('dots-default');
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <div className={classNames.join(' ')}>{props.children}</div>
  );
};

Dots.propTypes = {
  /**
   * Name of branding, any of "none (default), sport or tur".
   */
  branding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(['default', 'sport', 'tur'])
  ]),
  children: PropTypes.node,
  className: PropTypes.string
};

export default Dots;
